<template lang="pug">
    .masthead.white--text
      v-container.pa-12(:style="{'max-width': '600px'}")
        v-img(:src="image" max-width="600px")
        h1(v-html="title").white--text.my-8
        p(v-for="p in paragraphs", :key="p", v-html="p").white--text.light--weight
</template>

<script>
import { get } from "vuex-pathify";
export default {
  name: "SystemMasthead",
  data() {
    return {
      defaults: {
        title: "Your Brand&#39;s Unit Financials in One Place.",
        paragraphs: [
          `Unit Financials by Zeewise helps grow your brand by combining unit financial sources to provide actionable insight you can trust. Easily invite owners for one time integration with QuickBooks Online, Xero, FreshBooks.`
        ],
        image: require("@/assets/Onboarding/default_righthand.png")
      }
    };
  },
  computed: {
    ...get({
      activeComponent: "Onboarding/activeComponent",
      brandSpaceSubdomain: "Onboarding/registration@brandSpaceSubdomain",
      brandSpaceLabel: "Onboarding/registration@brandSpaceLabel"
    }),
    brandSpaceDisplay() {
      return this.brandSpaceLabel || "";
    },
    image() {
      return this.screens[this.activeComponent.name] &&
        this.screens[this.activeComponent.name].image
        ? this.screens[this.activeComponent.name].image
        : this.defaults.image;
    },
    title() {
      return this.screens[this.activeComponent.name] &&
        this.screens[this.activeComponent.name].title
        ? this.screens[this.activeComponent.name].title
        : this.defaults.title;
    },
    paragraphs() {
      return this.screens[this.activeComponent.name] &&
        this.screens[this.activeComponent.name].paragraphs
        ? this.screens[this.activeComponent.name].paragraphs
        : this.defaults.paragraphs;
    },
    screens() {
      return {
        CreateSystem: {
          image: require("@/assets/Onboarding/CreateSystem/onboarding_createsystem_reserve.png"),
          title: "Your Brand&#39;s Unit Financials in One Place.",
          paragraphs: [
            `A Brand Space is your private platform to view and manage the financial data from your units. You can easily invite users and control how much of your brand's data they can see.<br><br>To start, you'll send an invite to some unit-owners and they'll complete a one-time connection step to QuickBooks Online, Xero, FreshBooks.`
          ]
        },
        CreateSystemAccount: {
          image: require("@/assets/Onboarding/CreateSystem/onboarding_createsystem_account.png"),
          title: "Feel Empowered",
          paragraphs: [
            `Make confident, data-driven decisions from your unit financials and automate to replace spreadsheets and emailed reports.<br><br>See more with all current and historic income statement data.`
          ]
        },
        CreateSystemInvitations: {
          image: require("@/assets/Onboarding/CreateSystem/onboarding_createsystem_invites.png"),
          title: "Integration in a few clicks.",
          paragraphs: [
            `Invite units to connect to QuickBooks Online, Xero, or FreshBooks in a few clicks.<br><br>The one-time setup creates a connection to regularly update data in your Brand Space.<br><br>Manage invites or add more units any time in your Brand Space.`
          ]
        },
        InviteeJoin: {
          image: require("@/assets/Onboarding/Invitee/onboarding_invitee_join.png"),
          title: "You’ve been invited.",
          paragraphs: [
            `Welcome to the ${this.brandSpaceDisplay} Brand Space. You’ve been invited to contribute your Company’s financial information.`
          ]
        },
        ConnectUnit: {
          image: require("@/assets/Onboarding/Invitee/onboarding_invitee_connect.png"),
          title: "Integration in a few clicks.",
          paragraphs: [
            `Connect to QuickBooks Online, Xero, or FreshBooks in a few clicks.<br><br>The one-time setup creates a connection to regularly update Profit and Loss report information with your Brand Space.<br><br>Brand Admin will be able to share system averages with contributors.<br><br>Only Brand Admin may view unit data which always stays within the Brand Space and is not shared with other units.`
          ]
        },
        ConnectionInfoOne: {
          image: require("@/assets/Onboarding/Invitee/onboarding_invitee_unitdetails.png"),
          title: "Simply Connected",
          paragraphs: [
            `Zeewise provides financial wisdom for franchise brands and small businesses.<br><br>Our goal is to provide easy-to-use, reliable data, that unlocks potential for growth.<br><br>We understand the challenges facing franchise systems and strive to support their journey with simplicity in all our products.`
          ]
        },
        ConnectionInfoTwo: {
          image: require("@/assets/Onboarding/Invitee/onboarding_invitee_unitdetails.png"),
          title: "Simply Connected",
          paragraphs: [
            `Zeewise provides financial wisdom for franchise brands and small businesses.<br><br>Our goal is to provide easy-to-use, reliable data, that unlocks potential for growth.<br><br>We understand the challenges facing franchise systems and strive to support their journey with simplicity in all our products.`
          ]
        },
        ConnectionComplete: {
          image: require("@/assets/Onboarding/Invitee/onboarding_invitee_completedconnection.png"),
          title: "Simply Connected",
          paragraphs: [
            `Zeewise provides financial wisdom for franchise brands and small businesses.<br><br>Our goal is to provide easy-to-use, reliable data that unlocks potential for growth.<br><br>We understand the challenges facing franchise systems and strive to support their journey with simplicity in all our products.`
          ]
        }
      };
    }
  }
};
</script>

<style scoped>
.masthead {
  background: #4f5a6c;
  min-height: 100vh;
}
</style>
