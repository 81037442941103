<template lang="pug">
    .onboarding-view
        v-row
          v-col(cols="12" md="6")
            component(:is="activeComponent", v-bind="activeComponentProps", @click="setActiveItem({to: $event})")
          v-col(cols="12" md="6" style="padding:0;margin:0;")
            SystemMasthead
</template>

<script>
import { get, call } from "vuex-pathify";
import SystemMasthead from "@/components/Onboarding/SystemMasthead.vue";
export default {
  name: "OnboardingPage",
  components: { SystemMasthead },
  watch: {
    $route() {
      this.initPage();
    }
  },
  data() {
    return {};
  },
  created() {
    this.initPage();
  },
  mounted() {
    this.initUser();
  },
  computed: {
    ...get({
      activeComponent: "Onboarding/activeComponent",
      activeComponentProps: "Onboarding/activeComponentProps"
    })
  },
  methods: {
    ...call({
      setActiveItem: "Onboarding/setActiveItem",
      initUser: "Onboarding/initUser"
    }),
    initPage() {
      if (this.$route.params && this.$route.params.page) {
        this.setActiveItem({ to: this.$route.params.page });
      }
    }
  }
};
</script>

<style lang="scss">
.onboarding-view p {
  font-size: 1.1rem;
}
</style>
